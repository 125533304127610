export const stockDetailLabels = {
  title: "Stock Detail",
  back: "Back",
  dateHeader: "Date",
  dateAccessor: "date",
  stockTypeHeader: "Material Procurement",
  stockTypeAccessor: "stock.stock_type",
  inOutHeader: "In/Out",
  inoutAccessor: "status",
  balanceHeader: "Balance",
  balanceAccessor: "balance",
  viewTaskAllocation: "View Task Allocation",
  projectName: "Project Name :",
  serviceName: "Service Name :",
  mainActivity: "Main Activity :",
  subActivity: "Sub Activity :",
  tapsFitting: "Taps Fitting",
  remarks: 'Remarks',
  remarksAccessor: 'remarks'
};

export const stockDetailsList = [
  {
    id: 1,
    date: "19/02/2024",
    stock_type: "Cement",
    type: "In",
    balance: 3,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 2,
    date: "12/04/2024",
    stock_type: "Cement",
    type: "Out",
    balance: 5,
    status: "Block",
    remarks: 'Cement used in wall'
  },
  {
    id: 3,
    date: "22/08/2024",
    stock_type: "Cement",
    type: "Out",
    balance: 2,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 4,
    date: "06/07/2023",
    stock_type: "Cement",
    type: "In",
    balance: 3,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 5,
    date: "16/04/2024",
    stock_type: "Cement",
    type: "Out",
    balance: 1,
    status: "Block",
    remarks: 'Cement used in wall'
  },
  {
    id: 6,
    date: "03/04/2024",
    stock_type: "Cement",
    type: "In",
    balance: 6,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 7,
    date: "06/05/2024",
    stock_type: "Cement",
    type: "In",
    balance: 17,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 8,
    date: "12/05/2024",
    stock_type: "Cement",
    type: "Out",
    balance: 5,
    status: "Block",
    remarks: 'Cement used in wall'
  },
  {
    id: 9,
    date: "21/04/2024",
    stock_type: "Cement",
    type: "Out",
    balance: 1,
    status: "Active",
    remarks: 'Cement used in wall'
  },
  {
    id: 10,
    date: "19/02/2024",
    stock_type: "Cement",
    type: "In",
    balance: 1,
    status: "Active",
    remarks: 'Cement used in wall'
  },
];

export const viewTaskLabel = {
    projectName: "Project Name :",
    serviceName: "Item Name :",
    seniorName: "Senior Name :",
    employeeName: "Employee Name :",
    startEndTime: "Start date :",
    startAndTime: "Start & End Time :",
    mainActivity: "Main Activity :",
    pipesAllocation: "Pipes Allocation",
    subActivity: "Sub Activity :",
};
