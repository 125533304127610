export const contactuslist = {
    ContactList : 'ContactUs List',
    Redevelopmentt: 'Redeveploment List',
    channalPartner: 'Channel Partner List',
    PaartnerWithUs:'Partner With Us'
};

export const contactUsTabel = {
    title:'ContactsUsList',
    title1:'Partneer With Us',
    title2:'Redevelopment',
    title3:'Channel Partner',
    title4:'Expense Report',
    title5:'Stock Report',
    title6:'DPR Report',
    companyName : 'Company Name',
    companyNameAccessor: 'company_name',
    authorizedPeroson:'AuthorizedPeroson Name',
    authorizedPerosonAccessor:'authorized_person_name',
    businessTitle : 'Business Title',
    businessTitleAccessor : 'business_title',
    contactNo:'Contact No',
    contactNoAccessor:'contact_no',
    businessDetails: 'Business Details',
    businessDetailsAccessor:"business_details",
    visiteDate: 'Visit Date',
    visiteDateAccessor: 'visit_date',
    visiteLocation: 'Visite Location',
    visteImage: 'Visite Image',
    visteLocationAccessor:'visit_location',
    email:'Email',
    emailAccessor:'email',
    selcetSite: 'Site',
    startDate: "a Start Date",
    endDate:'a End Date',
    selcetSiteName:'Select Site',
    selcetDate:'Start Date',
    selcetEndDate: 'End Date',
    siteName:'Site name',
    contactuslist: 'ContactUs List',
    PartnerWith:'Partner With Us List',
    start_date:'Start date',
    end_date: 'End Date',
    action:"Action",
    filter:'Filter',
    close:'Colse',
    MoreRecords:'More Records'
};

export const channalPartner = {
    id:'id',
    Id:'Sr No',
    PartnerAccessor: 'channel_partner_name',
    PartnerName: 'Channal Partner Name',
    MobileNoAccessor: 'mobile_no',
    MobileNo: 'Mobile No',
    GSTIN:'GSTIN',
    GSTINAccessor: 'GSTIN',
    EmailIdAccessor:'email_id',
    EmailId: 'Email Id',
    CommisonCharge: 'commission_charge',
    commissionchargeAccessor : 'commission_charge',
    TypeOfPropertiesDetails: 'Type Of Properties Details',
    googlelocationpin: 'Google Location Pin',
    googlelocationpinAccessor: 'google_location_pin',
    nameofSocietyAccessor: 'name_of_Society',
    nameofSociety:'Name Of Society',
    Address:'Address',
    societytype:'Society Type',
    stageofredevelopment: "Stage Of Redevelopment",
    noofunits:'no of units',
    noofCommercialUnits: 'No Of CommercialUnits',
    ResidentialUnits:'No Of ResidentialUnits',
    membersagree:'Members Agree',
    contactperson:'Contact Prson',
    city:'City',
    subject:"Subject",
    subjectAccessor:'subject',
    email:'Email',
    emailAccessor:'email',
    messages:'Messages',
    messagesAccessor:'messages'


} 
export const siteSelect = [
    { label: "Construction", value: "Construction" },
    { label: "Consulting", value: "Consulting" },
    { label: "Architecture", value: "Architecture" },
    { label: "Main", value: "Main" },
];