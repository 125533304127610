export const servicesLabel = {
  serialName: "Sr.No",
  servicesName: "Item Name",
  action: "Action",
  services: "Items"
};

export const servicesKey = {
  servicesName: "service_name",
  id: "id"
};

export const searchTable = [
  {
    id: 8,
    name: "Patsy",
  },
  {
    id: 7,
    name: "Kerry",
  },
  {
    id: 6,
    name: "Traci",
  },
  {
    id: 5,
    name: "Noel",
  },
  {
    id: 4,
    name: "Robert",
  },
  {
    id: 3,
    name: "Shannon",
  },
  {
    id: 2,
    name: "Harold",
  },
  {
    id: 1,
    name: "Jonathan",
  },
];
