export const userLabel = {
  enter: "Enter",
  contact: "Contact No",
  password: "Password",
  newPassword : "New Password",
  email: "Email",
  welcomeBack: "Welcome Back !",
  confirmPassword: "Confirm Password",
  currentPassword : "Current Password",
  login: "Login",
  resetPassword: "Reset Password",
  forgotPassword: "Forgot Password",
  emailVerification: "Email Verification",
  createPassword: "Create New Password",
  name: 'Name',
  contactNo: 'Contact No',
  City: "City",
  State: "State",
  ZipCode:'Zip Code',
  Address: 'Address',
  ZipCodeValidationMessage: "must be a 6-digit number",
  EmailValidationMessage: "Invalid email format",
  PhoneNumber: 'Phone Number',
  EmailAddress: 'Email Address',
  
};

export const labels = {
  welcome: "Welcome Back !",
  signIn: "Sign in to continue to Building management.",
};

export const userTab = {
  personalDetails: ' Personal Details',
  ChangePassword: 'Change Password'
};
