//import Scss
import "./assets/scss/themes.scss";
import 'flatpickr/dist/themes/material_blue.css'

//imoprt Route
import Route from "./Routes";

function App() {
  return <Route />;
}

export default App;
