export const taskList = [
  {
    project_name: 'Sapath Heights',
    service_name: 'Wiring',
    senior: 'Amit Kumar',
    employee: 'Santosh Yadav',
  },
  {
    project_name: 'Sapath Heights',
    service_name: 'Painting',
    senior: 'Sudhir Sharma',
    employee: 'Kishan Patil',
  },
  {
    project_name: 'Solaris BH',
    service_name: 'Plumbing',
    senior: 'Rajiv Singhaniya',
    employee: 'Sanajy Nath',
  },
  {
    project_name: 'Solaris BH',
    service_name: 'Solar Panel',
    senior: 'Keshav Patel',
    employee: 'Steve Harrington',
  },
  {
    project_name: 'Solaris BH',
    service_name: 'Tiles Fitting',
    senior: 'Umesh Jadav',
    employee: 'Joel Garner',
  },
  {
    project_name: 'Empire State',
    service_name: 'Furniture',
    senior: 'John Wick',
    employee: 'Malcolm Marshal',
  },
  {
    project_name: 'Skyline',
    service_name: 'Elevator',
    senior: 'Peter Parker',
    employee: 'Curtney Walse',
  },
  {
    project_name: 'Skyline',
    service_name: 'Plaster',
    senior: 'Steve Rogers',
    employee: 'Glenn Mccgrath',
  },
  {
    project_name: 'Skyscrapper',
    service_name: 'Glass fitting',
    senior: 'Clerk Kent',
    employee: 'Chirs Hemsworth',
  },
  {
    project_name: 'The Twins',
    service_name: 'Plumbing',
    senior: 'Ben Affleck',
    employee: 'Tom Ellish',
  },
];

export const taskLabels = {
  title: 'Task Allocation',
  addTaskAllocationDetail: 'Add task allocation detail',
  projectName: 'Project name',
  serviceName: 'Item name',
  managerName: 'Manager name',
  day: "Day",
  senior: 'Senior',
  employee: 'Employee',
  mainActivity: 'Main activity',
  subActivity: 'Sub activity',
  startDateEndDate: 'Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  projectAccessor: 'project.project_name',
  serviceAccessor: 'service.service_name',
  seniorAccessor: 'manager.name',
  employeeAccessor: 'employee.name',
  taskAllocationList: 'Task allocation List',
  add: 'Add',
  projectManagerName: 'Project Manager Name',
  vendorName: 'Vendor name',
  employeeName: 'Employee Name',
  designation: 'Designation',
  location: "location",
  unit: "unit",
  skilledWorkers: "skilled Workers",
  unskilledWorkers: "unskilled Workers",
  totalWorkers: "total Workers",
  totalMenday: "total Manday",
  description: "description",
  output: "output",
  quantity: "quantity",
  issue: "Issues on site",
  solution: "Solution",
  report: "Report prepared by",
};

export const projectType = [
  {
    options: [
      { label: 'Select Project Name', value: '' },
      { label: 'Skyline', value: 'skyline' },
      { label: 'Sapath', value: 'sapath' },
      { label: 'Twins Tower', value: 'twins tower' },
      { label: 'Empire State', value: 'empire state' },
      { label: 'Antilia', value: 'antilia' },
    ],
  },
];

export const serviceType = [
  {
    options: [
      { label: 'Select Service Name', value: '' },
      { label: 'Plumbing', value: 'plumbing' },
      { label: 'Plaster', value: 'plaster' },
      { label: 'Painting', value: 'painting' },
      { label: 'Tiles Fitting', value: 'tilesfitting' },
      { label: 'Wiring', value: 'wiring' },
    ],
  },
];

export const seniorList = [
  {
    options: [
      { label: 'Select Senior Name', value: '' },
      { label: 'Rajat Sharma', value: 'Rajat Sharma' },
      { label: 'Sudhir Chaudhary', value: 'Sudhir Chaudhary' },
      { label: 'Arnab Goswami', value: 'Arnab Goswami' },
      { label: 'Sanjay Malhotra', value: 'Sanjay Malhotra' },
      { label: 'Ravish Kumar', value: 'Ravish Kumar' },
    ],
  },
];

export const employeeList = [
  {
    options: [
      { label: 'Select Employee Name', value: '' },
      { label: 'Tom Holland', value: 'Tom Holland' },
      { label: 'John Lawrance', value: 'John Lawrance' },
      { label: 'Arthur cruz', value: 'Arthur cruz' },
      { label: 'Ben Affleck', value: 'Ben Affleck' },
      { label: 'Steve Smith', value: 'Steve Smith' },
    ],
  },
];

export const designationType = [
  {
    options: [
      { label: 'Select Designation', value: '' },
      { label: 'Engineer', value: 'Engineer' },
      { label: 'Contractor', value: 'Contractor' },
      { label: 'Manager', value: 'Manager' },
      { label: 'Security', value: 'Security' },
      { label: 'Landlord', value: 'Landlord' },
    ],
  },
];

export const managerList = [
  {
    options: [
      { label: 'Select Manager Name', value: '' },
      { label: 'Haresh Mehta', value: 'Haresh Mehta' },
      { label: 'Vijay Patel', value: 'Vijay Patel' },
      { label: 'Karan Sarvaiya', value: 'Karan Sarvaiya' },
      { label: 'Jay Vaghela', value: 'Jay Vaghela' },
      { label: 'Ramesh Raval', value: 'Ramesh Raval' },
    ],
  },
];

export const vendorList = [
  {
    options: [
      { label: 'Select Vendor Name', value: '' },
      { label: 'Kamlesh Rakholiya', value: 'Kamlesh Rakholiya' },
      { label: 'Mehul Dudhatra', value: 'Mehul Dudhatra' },
      { label: 'Vatsal Zinzuvadiya', value: 'Vatsal Zinzuvadiya' },
      { label: 'Umesh Datt', value: 'Umesh Datt' },
      { label: 'Sandip Sharma', value: 'Sandip Sharma' },
    ],
  },
];
