//User module
export const LOGIN = "/login";
export const REGISTER = '/register';
export const DELETE_USER = '/deleteUser';
export const LIST_USER = '/listUsers'
export const SEND_OTP = "/sendOtp";
export const VERIFY_OTP = "/verifyOtp";
export const FORGOT_PASSWORD = "/forgotPassword";
export const UPDATEPROFILE = "/updateProfile";
export const VIEWPROFILE = "/viewProfile";
export const CHANGEPASSWORD = '/resetPassword'
export const FILEUPLOAD = '/fileUploads'
//List APis
export const LIST_CITY = '/listOfCity';
export const LIST_STATE = '/listOfState';
export const LIST_DESIGNATION = '/listOfDesignation';
export const LIST_SERVICE = '/service/list';
export const LIST_OF_VENDOR_SERVICE = '/service/listUserService';

//Master Service
export const SERVICE = "/service";

//Project 
export const PROJECT = "/project";
export const LIST_PROJECT = "/project/list";
export const LIST_USER_PROJECT = "/project/listUserProject";

//Expanse
export const EXPENSE = "/expense";
export const EXPENSE_LIST = "/expense/list";
export const LISTLASTEXPENSE = "/expense/listLastExpense";
export const DELETEEXPENSE = "/expense/deleteExpense";

//Stock Type
export const STOCKTYPELIST = "/stock/stockType/list";
export const STOCKTYPEADD ="/stock/stockType";
export const STOCKTYPEEDIT = "/stock/stockType";
export const STOCKTYPEDELETE = "/stock";

//stock
export const STOCK = "/stock";
export const EDITSTOCK = "/stock/";
export const LISTSTOCK = "/stock/stockList";
export const VIEWSTOCK = "/stock/viewStockDetails/";

//task allocation
export const TASK = "/task/list";
export const TASKADD = "/task";
export const DELETETASK = "/task/";
export const LISTOFUNIT ="/task/listOfUnit"

//report
export const REPORT = "/report/dailyProgressReport";
export const STOCKREPORT = "/report/stockReport";
export const EXPENSEREPORT = "/report/expenseReport";
export const SENDREPORT = "/report/sendReport";

//inquriy
export const CONTACTUS = '/inquiry/listOfBusinessPartner';
export const LISTOfCHANNEL = '/inquiry/listOfChannelPartner';
export const LISTOFREDEVELOP = '/inquiry/listOfRedevelopmentInquiry';
export const LISTOFCONTACTUS = '/inquiry/listOfContactUs';
