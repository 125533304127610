export const employeeTable = [
  {
    id: 1,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 2,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 3,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 4,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 5,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 6,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
  {
    id: 7,
    name: "krishna",
    email: "krishna@gmail.com",
    contactNo: "1234567890",
    city: "rajkot",
    state: "gujarat",
    role: "Manager",
    status: "Active",
  },
];

export const employeeLabel = {
  Title: "Stack Holder",
  EmployeeDetails: "Stack Holder Details",
  AddEmployee: "Add Stack Holder",
  EditEmployee: "Edit Stack Holder",
  Name: "Name",
  Email: "Email",
  ContactNo: "Contact No",
  City: "City",
  State: "State",
  Role: "Role",
  Status: "Status",
  Action: "Action",
  Address: "Address",
  ZipCode: "ZipCode",
  Designation: "Designation",
  projectName: "Project",
  ServiceType: "Service Type",
};

export const employeeKey = {
  Name: "name",
  Email: "email",
  ContactNo: "contact_no",
  City: "city",
  State: "state",
  Role: "role",
  Status: "status",
  Designation: "designation",
  Action: "action",
};

export const stateType = [
  {
    options: [
      { label: "Select State Type", value: "" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Maharashtra", value: "Maharashtra" },
    ],
  },
];

export const cityType = [
  {
    options: [
      { label: "Select City Type", value: "" },
      { label: "Ahmadabad", value: "Ahmadabad" },
      { label: "Rajkot", value: "Rajkot" },
      { label: "Surat", value: "Surat" },
      { label: "Diu", value: "Diu" },
    ],
  },
];

export const serviceType = [
  {
    options: [
      { label: "Plumbing", value: "Plumbing" },
      { label: "Electrician", value: "Electrician" },
      { label: "Designing", value: "Designing" },
      { label: "Fire safety", value: "Fire safety" },
    ],
  },
];

export const roleType = [
  // { label: "Select Role Type", value: "" },
  { label: "Vendor", value: "Vendor" },
  { label: "Employee", value: "Employee" },
  { label: "Manager", value: "Manager" },
];

export const designationType = [
  {
    options: [
      { label: "Select Designation Type", value: "" },
      { label: "Security", value: "Security" },
      { label: "Supervisor", value: "Supervisor" },
      { label: "Engineer", value: "Engineer" },
    ],
  },
];
