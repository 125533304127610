
export const expanseLabels = {
  title: "Expense",
  addExpanseDetail: "Add expense detail",
  expanseList: "Expense List",
  employee: "employee",
  project: "project name",
  amount: "Amount",
  totalAmount : "Total Amount",
  submit: "Submit",
  projectManager: "Project manager",
  date: "Date",
  paidTo: "Paid to",
  paymentMode: "Payment mode",
  purpose: "Purpose",
  viewExpanseDetail: "View Expense Detail",
  validationMessages : "Paid to field must contain only letters"
};

export const expanseTable = {
  paidTo: "Paid To",
  paidToAccessor: "paid_to",
  project: "Project name",
  projectManager: "Project Manager",
  projectManagerAccessor: "project_manager.name",
  projectAccessor: "project.project_name",
  amount: "Amount",
  amountAccessor: "amount",
  action: "Action",
  date: "Date",
  dateAccessor: "date",
};

export const expanseTableList = [
  {
    project: "Sky Heights",
    project_manager: "Rajesh Kumar",
    paid_to: "Raj Prajapati",
    date: "15/02/2024",
    amount: "500000",
  },
  {
    project: "Sapath",
    project_manager: "Amit Shah",
    date: "16/02/2024",
    paid_to: "Nikunj Patel",
    amount: "150000",
  },
  {
    project: "Tall Oaks",
    project_manager: "Ashok Gohil",
    date: "17/02/2024",
    paid_to: "Harsh Rathod",
    amount: "650000",
  },
  {
    project: "Solaris BH",
    project_manager: "Uday Thakur",
    date: "18/02/2024",
    paid_to: "Vivek Munjani",
    amount: "71200",
  },
  {
    project: "Panchvati",
    project_manager: "Pratham Maheta",
    date: "19/02/2024",
    paid_to: "Chirag Bhavsar",
    amount: "640222",
  },
  {
    project: "Maruti Plaza",
    project_manager: "Raj Soni",
    date: "12/02/2024",
    paid_to: "Jitendra Vaghela",
    amount: "4520545",
  },
  {
    project: "Ganesh Meridian",
    project_manager: "Deep Panchal",
    date: "21/02/2024",
    paid_to: "Jignesh Kumar",
    amount: "550552",
  },
  {
    project: "Sky Heights",
    project_manager: "Rohan Raval",
    date: "22/02/2024",
    paid_to: "Skyline",
    amount: "48563205",
  },
  {
    project: "Hyatt",
    project_manager: "Kishan Joshi",
    date: "23/02/2024",
    paid_to: "Mohit Patel",
    amount: "6541056",
  },
  {
    project: "Empire State",
    project_manager: "Krunal Prajapati",
    date: "24/02/2024",
    paid_to: "Manthan Raval",
    amount: "6541025",
  },
];

export const employeeData = [
  {
    options: [
      { label: "Select Employee Name", value: "" },
      { label: "Hardik Sarswa", value: "Hardik Sarswa" },
      { label: "Vishesh Shah", value: "Vishesh Shah" },
      { label: "Parth Patel", value: "Parth Patel" },
      { label: "Manan Mehta", value: "Manan Mehta" },
      { label: "Jayesh Padhiyar", value: "Jayesh Padhiyar" },
      { label: "Kuldeepsinh Solanki", value: "Kuldeepsinh Solanki" },
      { label: "Jigar Parmar", value: "Jigar Parmar" },
      { label: "Ujas Shah", value: "Ujas Shah" },
    ],
  },
];

export const projectData = [
  {
    options: [
      { label: "Select Project Name", value: "" },
      { label: "Skyline", value: "Skyline" },
      { label: "Sapath", value: "Sapath" },
      { label: "Empire State", value: "Empire State" },
      { label: "Times Square", value: "Times Square" },
      { label: "Hyatt", value: "Hyatt" },
    ],
  },
];

export const paymentMode = [
  // { label: "Select Payment Mode", value: "" },
  { label: "Cash", value: "Cash" },
  { label: "Card", value: "Card" },
  { label: "Online", value: "Online" },
];

export const viewExpanse = [
  {
    projectName: "Project Name :",
    theTwins: "The Twins",
  },
  {
    projectManager: "Project Manager :",
    managerName: "Keshav Maheta",
  },
  {
    date: "Date :",
    dateData: "16/03/2024",
  },
  {
    paidTo: "Paid To :",
    PaidToName: "Rajiv Patel",
  },
  {
    mode: "Mode :",
    mode_type: "Online",
  },
  {
    amount: "Amount :",
    amount_value: "6500",
  },
  {
    purpose: "Purpose :",
    purpose_date: "Buy Material",
  },
];